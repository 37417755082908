<!-- eslint-disable -->
<template>
  <v-app>
    <p-v-app-bar />

    <p-v-view />
  </v-app>
</template>

<script>
/* eslint-disable */
  export default {
    name: 'PublicLayout',

    components: {
      PVAppBar: () => import('../../components/publicview/PVAppBar'),
      PVView: () => import('../../components/publicview/PVView'),
    // },
  },
}
</script>